import React, { useState } from "react";

export const initUser: IUser = {
  waitingListSubmitted: false,
  investorListSubmitted: false,
  languageSetManually: false,
};
export const storage = {
  getUser: () =>
    typeof window !== `undefined`
      ? JSON.parse(
          window.localStorage.getItem("user") || JSON.stringify(initUser)
        )
      : JSON.stringify(initUser),
  setUser: (user: IUser) =>
    typeof window !== `undefined`
      ? window.localStorage.setItem("user", JSON.stringify(user))
      : "",
  clearUser: () => window.localStorage.removeItem("user"),
};
export interface UserContextType {
  user: IUser;
  setMingleUser: (user: IUser, callback: VoidFunction) => void;
}

export interface IUser {
  waitingListSubmitted?: boolean;
  investorListSubmitted?: boolean;
  languageSetManually?: boolean;
}
export const UserContext = React.createContext<UserContextType | undefined>({
  user: initUser,
  setMingleUser: () => {},
});

export const UserContextProvider: React.FC<any> = ({ children }) => {
  const [user, setUser] = useState<IUser>(storage.getUser());
  let setMingleUser = (user: IUser, callback: VoidFunction) => {
    setUser(user);
    storage.setUser(user);
    callback();
  };
  let value = { user, setMingleUser };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
export const useUser = () => {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a authProvider");
  }
  return context;
};
